<template>
  <div class="pa2">
    <router-link
      class="link black dim dib"
      :to="$i18nRoute({ name: 'product', params: { sku: product.sku } })"
    >
      <h2 class="tc ttu vidaloka f3">{{ product.name }}</h2>
    </router-link>

    <div
      v-html="product.short_description"
      class="karla tracked-light lh-copy"
    ></div>

    <div class="mt4">
      <router-link
        class="f6 link dim ph3 pv2 mb2 dib white bg-black lh-copy roboto sans-serif ttu tracked tc b"
        :to="$i18nRoute({ name: 'product', params: { sku: product.sku } })"
        >{{ $t("product.find_out_more") }}</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "product-item",
  //	serverCacheKey: props => props.product.sku, // for ssr cache item component
  props: {
    product: Object
  },
  methods: {},
  computed: {}
};
</script>
