<template>
  <section class="w-100 f6 mt0-l mt4 ph4-l ph3">
    <div class="mw9 center">
      <div v-if="error" class="dark-red">{{ error }}</div>
      <div v-if="sorted()">
        <div
          class="cf"
          v-for="i in Math.ceil(sorted().length / 3)"
          v-bind:key="i"
        >
          <article
            class="fl w-100 w-third-ns mb3 ph2-ns"
            v-for="product in sorted().slice((i - 1) * 3, i * 3)"
            v-bind:key="product.sku"
          >
            <div class="bg-white tc">
              <router-link
                class="link dim dib"
                :to="
                  $i18nRoute({ name: 'product', params: { sku: product.sku } })
                "
              >
                <van-image fit="contain" :src="image(product.sku)">
                  <template #loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
                <!-- <img v-if="image(product.sku)" :src="image(product.sku).src" /> -->
              </router-link>
              <product v-bind:product="product" />
            </div>
          </article>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Product from "../components/Product.vue";
import { mapGetters, mapState } from "vuex";
import updateTdk from "../utils/tdk";
// READMORE:
// https://github.com/vuejs/vuex/blob/dev/examples/shopping-cart/store/modules/cart.js
// https://github.com/vuejs/vuex/blob/dev/examples/shopping-cart/components/ShoppingCart.vue

export default {
  name: "product-list",
  tdk() {
    return {
      title: "PIIIAshop | Van life Suomi",
      description:
        "Pakuprojekti tai pakun rakentaminen mielessä? Helpota tiedonhankintaa, tuotevertailua ja välty virheostoksilta.",
      keywords:
        "pakuprojekti, piiia, pakun rakentaminen, reissupakun rakentaminen, vanlife suomi, van life suomi, vanlife finland, van life finland, koti pakussa, liikkuva koti, minikoti, minitalo, miniasunto, asuntoauton rakentaminen, matkailuauton rakentaminen, paku projekti, pakun rakennus, matkailuauto, asuntoauto, retkeilyauto, reissupaku, retkipaku, retkipakun rakentaminen, retkeilyauton rakentaminen",
      ssrHeadAddInfo: "",
      language: this.language
    };
  },
  // Server-side only
  // This will be called by the server renderer automatically
  serverPrefetch() {
    return this.fetchProducts();
  },
  // Client-side only
  mounted() {
    this.fetchLocalization().then(() => {
      this.fetchProducts().then(() => {
        this.loaded();
      });
    });
  },
  components: {
    product: Product
  },
  computed: {
    ...mapGetters("product", {
      loading: "loading",
      error: "error",
      products2: "products2",
      products: "products"
    }),
    ...mapGetters("user", {
      residentCode: "residentCode",
      currency: "currency",
      localization: "localization"
    }),
    ...mapGetters("image", {
      error: "error",
      loading: "loading",
      imageIdsBySku: "imageIdsBySku",
      images: "images"
    }),
    language() {
      return this.$route.params.lang;
    }
  },
  methods: {
    // NOTICE: must return the dispatched action. Otherwise SSR will not work as expected.
    fetchProducts() {
      //this.$loading(true);
      return this.$store
        .dispatch("product/products", {
          language: this.language
        })
        .finally(() => {
          // this.$loading(false);
          updateTdk.call(this);
        });
    },
    fetchLocalization() {
      return this.$store.dispatch("user/localization", {
        cookie: this.$cookie,
        router: this.$router
      });
    },
    loaded() {
      this.products2.forEach(item => {
        this.$store.dispatch("image/loadImages", {
          pageSize: 1,
          sku: item.sku,
          pageToken: 0
        });
      });
      //}
    },
    image(sku) {
      if (this.imageIdsBySku(sku) == null) {
        return null;
      } else if (this.images[this.imageIdsBySku(sku)] == null) {
        return null;
      }
      return this.images[this.imageIdsBySku(sku)].src;
    },
    sorted() {
      return this.products2.sort(function(a, b) {
        return a.order_at - b.order_at;
      });
    }
  },
  watch: {
    // call again the method if the route changes
    //$route: "fetchProducts",
    currency(newValue, oldValue) {
      this.$store.commit("product/delete", this.products2);
      this.fetchProducts();
    },
    residentCode(newValue, oldValue) {
      this.$store.commit("product/delete", this.products2);
      this.fetchProducts();
    },
    language(newValue, oldValue) {
      this.$store.commit("product/delete", this.products2);
      this.fetchProducts();
    }
  }
};
</script>
